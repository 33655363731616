
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { getLedgerAll, getLedgerListExcel, getLedgerPropList, getLedgerProptypeAll } from "@/api/request/pipelining";

//组件
@Component({
  name: "PropsFlow",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private total = 0; //总数
  private list: any[] = []; //列表
  private typeList: any = []; //类型
  private listLoading = true; // 列表加载
  private productStatusEx = []; //道具类型
  private revenueTotal: any = {}; //总收入

  //时间参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
            // that.listQuery.time = []
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //请求数据
  private listQuery: any = {
    //页码数据
    page: 1,
    row: 20,

    //时间数据
    time: [],
    end_time: "",
    start_time: "",

    //请求数据
    type: -1,
    excel: false,
    nickname: "",
    identity: -1,
    to_identity: -1,
    userid: undefined,
    propid: undefined,
  };

  //发送人类型
  private identityList: any[] = [
    { id: -1, name: "全部发送人身份" },
    { id: 0, name: "正常" },
    { id: 1, name: "内部用户" },
  ];

  //接受人类型
  private toIdentityList: any[] = [
    { id: -1, name: "全部接受人身份" },
    { id: 0, name: "正常" },
    { id: 1, name: "内部用户" },
  ];

  //创建
  created() {
    //初始化时间
    this.initTime();

    //获取下拉框
    this.getLedgerAll();

    //获取道具类型
    this.diamondProptype();

    //获取列表
    this.getList();
  }

  //初始化时间
  private initTime(): void {
    //获取当天时间
    const currentDay = DTCls.getCurDateTimeYMD();

    //数据赋值
    this.listQuery.end_time = `${currentDay}`;
    this.listQuery.start_time = `${currentDay}`;
    this.listQuery.time = [this.listQuery.start_time, this.listQuery.end_time];
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.excel = false;
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
    } else {
      this.listQuery.start_time = "";
      this.listQuery.end_time = "";
    }
    if (this.listQuery.userid) this.listQuery.userid = Number(this.listQuery.userid);

    //获取数据
    const { data } = await getLedgerPropList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;
    this.revenueTotal = data.revenue_total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //重置查询
  private handleReset(): void {
    //初始化时间
    this.initTime();

    //请求数据
    this.listQuery.type = -1;
    this.listQuery.nickname = "";
    this.listQuery.userid = undefined;
    this.listQuery.propid = undefined;

    //获取数据
    this.getList();
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;
    this.listQuery.excel = false;

    //获取数据
    this.getList();
  }

  //导出Excel
  private async handleExcel() {
    //数据赋值
    this.listQuery.excel = true;
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
    } else {
      this.listQuery.start_time = "";
      this.listQuery.end_time = "";
    }
    if (this.listQuery.userid) this.listQuery.userid = Number(this.listQuery.userid);

    //请求数据
    const { data } = await getLedgerListExcel(this.listQuery);

    //保存数据
    this.listQuery.time && this.listQuery.time.length == 2 ? saveAs(data, `${this.listQuery.time[0]}--${this.listQuery.time[1]}道具流水`) : saveAs(data, "道具流水总览");
  }

  //获取下拉框
  private async getLedgerAll() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const res = await getLedgerAll();

    //数据赋值
    this.productStatusEx = res.data;
  }

  //获取类型
  private async diamondProptype() {
    //获取数据
    const res = await getLedgerProptypeAll();

    //数据赋值
    this.typeList = res.data;
    this.typeList.unshift({ id: -1, name: "全部" });
  }

  //初始化ID
  private idInputHandler(val: any): void {
    if (val.trim() == "") this.listQuery.userid = undefined;
  }
}
